// import Vue from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'
// import VueCompositionApi from "@vue/composition-api";

// import Buefy from 'buefy'
// import 'buefy/dist/buefy.css'
// import '@mdi/font/css/materialdesignicons.css'

// import vuetify from '@/plugins/vuetify'
// import { useApollo } from '@/plugins/apollo'
import config from 'config'
import 'normalize.css'
// import { createPinia, PiniaPlugin } from 'pinia'

// Vue.config.productionTip = false
// Vue.use(VueCompositionApi)
// Vue.use(Buefy)
// Vue.use(PiniaPlugin)

console.log('### DEBUG config ###')
console.log(config)

const originUrl = window.location.href

if (process.env.NODE_ENV === 'production' && window.location.origin === 'https://www.apotoru.biz') {
  console.log('production redirect')
  const redirectUrl = originUrl.replace('https://www.apotoru.biz', 'https://apotoru.biz')
  window.location.href = redirectUrl
} else if (process.env.NODE_ENV === 'production' && window.location.origin === 'https://www.stg-apotoru.com') {
  console.log('staging redirect')
  const redirectUrl = originUrl.replace('https://www.stg-apotoru.com', 'https://stg-apotoru.com')
  window.location.href = redirectUrl
}

// validation
// import { extend, ValidationProvider, ValidationObserver, localize } from 'vee-validate' // 使用する機能
// import ja from 'vee-validate/dist/locale/ja.json' // エラーメッセージの日本語化用
// import * as rules from 'vee-validate/dist/rules'
// for (let rule in rules) {
//   extend(rule, rules[rule])
// }
// Vue.component('ValidationProvider', ValidationProvider)
// Vue.component('ValidationObserver', ValidationObserver)
// localize('ja', ja)
// const phoneValidationRule = {
//   message (fieldName) {
//     return `${fieldName}の値が不正です`;
//   },
//   validate (value) {
//     return new Promise(resolve => {
//       resolve({ valid: /^\d{10,12}$/.test(value.replace("-", "").replace(/-/g, "")) })
//     });
//   }
// }
// const urlValidationRule = {
//   validate(value) {
//     var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
//       '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
//       '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
//       '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
//       '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
//       '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
//     return pattern.test(value);
//   },
//   message (fieldName) {
//     return `${fieldName}が不正です`;
//   },
// }
// extend('phone', phoneValidationRule);
// extend('url', urlValidationRule)
// // Vue.use(VeeValidate, {
// //   mode: 'eager'
// // });

// console.log('### DEBUG process.env ###')
// console.log(process.env)

// console.log('### DEBUG config ###')
// console.log(config)

// import { DefaultApolloClient } from '@vue/apollo-composable'

// const { apolloProvider, apolloClient } = useApollo(config.graphqlUrl)
// const pinia = createPinia()

// new Vue({
//   router,
//   // store,
//   vuetify,
//   apolloProvider,
//   provide: {
//     [DefaultApolloClient]: apolloClient,
//   },
//   pinia,
//   render: h => h(App)
// }).$mount('#app')
